import { isFunction } from 'lodash';

const clickListener = (el, fn) => {
    if (isFunction(fn)) {
        fn(el);
    }
};

export const ElementClick = {
    inserted: (el, binding) => {
        const { selector, fn } = binding.value;
        const elements = el.querySelectorAll(selector);
        elements.forEach((element) => {
            const handler = clickListener.bind(null, element, fn);
            element.addEventListener('click', handler);
            element._handler = handler;
        });
    },
    unbind: (el, binding) => {
        const { selector } = binding.value;
        const elements = el.querySelectorAll(selector);
        elements.forEach((element) => {
            element.removeEventListener('click', element._handler);
            delete element._handler;
        });
    }
};
