import Vue from 'vue';
import { make } from 'vuex-pathify';

import { fullName } from '@/common';

const defaultState = () => ({
    refreshing: false,
    selectedPatient: null,
    patients: null
});

const state = defaultState();
const getters = {
    ...make.getters(state),
    selectedPatientName: (state) => {
        return fullName(state.selectedPatient?.data?.family[0]);
    }
};
const mutations = {
    ...make.mutations(state),
    resetDefaultState(state) {
        Object.assign(state, defaultState());
    }
};
const actions = {
    ...make.actions(state),
    async selectPatient({ commit }, { patientId, type = 'view' }) {
        try {
            const { data: patient } = await Vue.prototype.$api.get(`/practitioner/patient?patientId=${patientId}&type=${type}`);
            if ('view' === type) {
                commit('refreshing', true);
                Vue.prototype.$api
                    .get(`/practitioner/patient/cds?patientId=${patientId}`)
                    .then(({ data: cds }) => {
                        patient.cds = cds;
                        commit('selectedPatient', patient);
                    })
                    .finally(() => {
                        commit('refreshing', false);
                    });
            }
            commit('selectedPatient', patient);
            if (patient?.data) {
                commit('loadPatientData', patient.data, { root: true });
            }
        } catch (error) {
            commit('selectedPatient', null);
            commit('resetDefaultState', null, { root: true });
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
