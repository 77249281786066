import Vue from 'vue';
import { make } from 'vuex-pathify';
import { findIndex } from 'lodash';
import moment from 'moment';
import { studyConditions, studyParameters, isStudyLocked } from '@/common';

const defaultState = () => ({
    patient: null
});

const state = defaultState();
const getters = {
    ...make.getters(state),
    selectedStudyId: (state) => {
        return state.patient?.meta?.selectedStudyId;
    },
    study: (state, getters) => {
        return getters.studies?.find((study) => study.id === getters.selectedStudyId);
    },
    studies: (state) => {
        return state.patient?.meta?.studies;
    },
    studyConditions: (state, getters) => {
        return studyConditions(getters.selectedStudyId, state.patient);
    },
    studyParameters: (state, getters) => {
        return studyParameters(getters.selectedStudyId);
    },
    studyLocked: (state, getters) => {
        return isStudyLocked(getters.study);
    }
};
const mutations = {
    ...make.mutations(state),
    selectStudy(state, studyId) {
        if (state.patient?.meta) {
            state.patient.meta.selectedStudyId = studyId;
        }
    },
    completeStudy(state, studyId) {
        if (state.patient?.meta?.studies) {
            const index = findIndex(state.patient.meta.studies, { id: studyId });
            state.patient.meta.studies[index].finished = new Date();
            const params = studyParameters(studyId);
            if (params && params.lockWhenFinished) {
                state.patient.meta.studies[index].locked = true;
            }
        }
    },
    resetDefaultState(state) {
        Object.assign(state, defaultState());
    }
};
const actions = {
    ...make.actions(state),
    async selectStudy({ commit, state }, { studyId, router }) {
        commit('selectStudy', studyId);
        const { data: patient } = await Vue.prototype.$api.post('/patient', { meta: state.patient.meta });
        commit('patient', patient);
        if (router) {
            if (router.history.current.path !== '/') {
                await router.replace('/');
            }
        }
    },
    async completeCurrentStudy({ commit, state, getters }) {
        commit('completeStudy', getters.selectedStudyId);
        const { data: patient } = await Vue.prototype.$api.post('/patient', { meta: state.patient.meta });
        commit('patient', patient);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
