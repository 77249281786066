import Vue from 'vue';
import { make } from 'vuex-pathify';

import { fullName } from '@/common';

import { findIndex, map } from 'lodash';

const defaultState = () => ({
    search: '',
    searchType: 'contains',
    patient: null,
    selectedPatient: null,
    organizations: null
});

const state = defaultState();
const getters = {
    ...make.getters(state),
    selectedPatientName: (state) => {
        return fullName(state.selectedPatient?.data?.family[0]);
    }
};
const mutations = {
    ...make.mutations(state),
    organizations(state, organizations) {
        const pagination = {};
        state.organizations?.forEach((organization) => (pagination[organization.id] = organization.pagination)); // save any previous pagination values
        state.organizations = map(organizations, (organization) => {
            return Object.assign({ view: 'patients', filter: null, pagination: pagination[organization.id] || { page: 1, itemsPerPage: 10 } }, organization);
        });
    },
    updateOrganization(state, organization) {
        const index = findIndex(state.organizations, { id: organization.id });
        if (index !== -1) {
            state.organizations.splice(index, 1, Object.assign({ view: 'patients', filter: null }, organization));
        }
    },
    addPatient(state, patient) {
        const index = findIndex(state.organizations, { id: patient.organizationId });
        if (index !== -1) {
            state.organizations[index].patients.unshift(patient);
        }
    },
    updatePatient(state, patient) {
        const index = findIndex(state.organizations, { id: patient.organizationId });
        if (index !== -1) {
            const patientIndex = findIndex(state.organizations[index].patients, { id: patient.id });
            if (patientIndex !== -1) {
                state.organizations[index].patients.splice(patientIndex, 1, patient);
            }
        }
    },
    deletePatient(state, patient) {
        const index = findIndex(state.organizations, { id: patient.organizationId });
        if (index !== -1) {
            const patientIndex = findIndex(state.organizations[index].patients, { id: patient.id });
            if (patientIndex !== -1) {
                state.organizations[index].patients.splice(patientIndex, 1);
            }
        }
    },
    addUser(state, { user, organization }) {
        const index = findIndex(state.organizations, { id: organization.id });
        if (index !== -1) {
            state.organizations[index].users.unshift(user);
        }
    },
    editUser(state, { user, organization }) {
        const index = findIndex(state.organizations, { id: organization.id });
        if (index !== -1) {
            const userIndex = findIndex(state.organizations[index].users, { id: user.id });
            if (userIndex !== -1) {
                state.organizations[index].users.splice(userIndex, 1, user);
            }
        }
    },
    deleteUser(state, { user, organization }) {
        const index = findIndex(state.organizations, { id: organization.id });
        if (index !== -1) {
            const userIndex = findIndex(state.organizations[index].users, { id: user.id });
            if (userIndex !== -1) {
                state.organizations[index].users.splice(userIndex, 1);
            }
        }
    },
    resetDefaultState(state) {
        Object.assign(state, defaultState());
    }
};
const actions = {
    ...make.actions(state),
    async selectPatient({ commit }, { patientId, type = 'view' }) {
        commit('busy', true, { root: true });
        try {
            const { data: patient } = await Vue.prototype.$api.get(`/researcher/patient?patientId=${patientId}&type=${type}`);
            commit('selectedPatient', patient);
            if (patient?.data) {
                commit('loadPatientData', patient.data, { root: true });
            }
        } catch (error) {
            commit('selectedPatient', null);
            commit('resetDefaultState', null, { root: true });
        }
        commit('busy', false, { root: true });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
