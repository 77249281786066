import Vue from 'vue';
import Vuex from 'vuex';
import i18n from './i18n';

import pathify from './pathify';
import { make } from 'vuex-pathify';

import { debounce, filter, find, findIndex, union } from 'lodash';
import { initFamily, createFamilyMember, isPaternal, isMaternal, wait } from '@/common';
import {
    generalRequired,
    isGeneralValid,
    demographicsRequired,
    isDemographicsValid,
    isCancerValid,
    isPregnancyValid,
    isMetricsValid,
    isLabsValid,
    isDietValid,
    isExerciseValid,
    isCardiovascularValid,
    isConditionsValid,
    isValid,
    allFamilyMembersValid
} from '@/common';

Vue.use(Vuex);

import patient from '@/modules/patient';
import provider from '@/modules/provider';
import researcher from '@/modules/researcher';

const modules = {
    patient,
    provider,
    researcher
};

const autoSaveDebounceDelay = 2500; // 2.5 seconds

const debouncePreferencesSave = debounce(async (store, state) => {
    try {
        const { data: meta } = await Vue.prototype.$api.post(`${store.getters.userType}/meta`, { meta: { ...state.user.meta, preferences: { ...state.user.meta?.preferences, ...state.preferences } } });
        const user = { ...state.user, meta };
        store.commit('user', user);
    } catch (error) {
        store.set('error', { error, wasSaving: true });
    }
}, autoSaveDebounceDelay);

const debouncePatientSave = debounce(async (store, state, mutation) => {
    let url = '/patient';
    if ('practitioner' === store.getters.userType) {
        url = `/provider/patient?patientId=${store.getters['provider/selectedPatient'].id}`;
    } else if ('researcher' === store.getters.userType) {
        url = `/researcher/patient?patientId=${store.getters['researcher/selectedPatient'].id}`;
    }
    try {
        const { data: patient } = await Vue.prototype.$api.post(url, { data: { __v: state.__v, family: state.family }, mutation });
        store.set('__v', patient.data.__v);
        if ('patient' === store.getters.userType) {
            store.commit('patient/patient', patient);
        }
        store.set('error', null);
    } catch (error) {
        store.set('error', { error, wasSaving: true });
    }
}, autoSaveDebounceDelay);

const flushDebounced = async () => {
    await debouncePreferencesSave.flush();
    await debouncePatientSave.flush();
};

const autoSave = (store) => {
    const ignoredMutations = [
        '__v',
        'loadPreferences',
        'loadPatientData',
        'resetData',
        'resetDefaultState',
        'busy',
        'user',
        'organization',
        'mode',
        'tourActive',
        'selectedUuid',
        'error',
        'sessionExpires',
        /^patient\//,
        /^provider\//,
        /^researcher\//
    ];

    store.subscribe(async (mutation, state) => {
        if ('user' === mutation.type) {
            store.commit('loadPreferences', mutation.payload?.meta?.preferences);
        } else if (/^preferences/.test(mutation.type)) {
            debouncePreferencesSave(store, state);
        } else if (
            !ignoredMutations.some((ignoredMutation) => {
                if (ignoredMutation instanceof RegExp) {
                    return ignoredMutation.test(mutation.type);
                }
                return ignoredMutation === mutation.type;
            })
        ) {
            debouncePatientSave(store, state, mutation);
        }
    });
};

const defaultState = () => ({
    preferences: {
        breakpoints: 'default',
        hints: true,
        tour: true
    },
    busy: false,
    user: null,
    organization: null,
    mode: null,
    tourActive: false,
    selectedUuid: null,
    error: null,
    sessionExpires: null,
    __v: null,
    family: initFamily()
});

const state = defaultState();

const getters = {
    ...make.getters(state),
    userType: (state) => {
        return state.user?.type;
    },
    hintsEnabled: (state, getters) => {
        return 'patient' === getters.userType && state.preferences.hints;
    },
    tourEnabled: (state, getters) => {
        return 'patient' === getters.userType && state.preferences.tour;
    },
    language: (state) => {
        return state.family[0].language || 'en';
    },
    familyMember: (state) => (uuid) => {
        return find(state.family, { uuid: uuid });
    },
    familyMemberIndex: (state) => (uuid) => {
        return findIndex(state.family, { uuid: uuid });
    },
    relative: (state) => (relation) => {
        return find(state.family, { relation: relation });
    },
    relativeIndex: (state) => (relation) => {
        return findIndex(state.family, { relation: relation });
    },
    selectedFamilyMember: (state, getters) => {
        return getters.familyMember(state.selectedUuid);
    },
    self: (state) => {
        return state.family[0];
    },
    isSelfValid: (state) => {
        return isValid(state.family[0]);
    },
    isFamilyCreated: (state) => {
        return state.family.length > 1;
    },
    generalRequired: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return generalRequired(person);
    },
    isGeneralValid: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return isGeneralValid(person);
    },
    demographicsRequired: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return demographicsRequired(person);
    },
    isDemographicsValid: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return isDemographicsValid(person);
    },
    isCancerValid: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return isCancerValid(person);
    },
    isPregnancyValid: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return isPregnancyValid(person);
    },
    isConditionsValid: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return isConditionsValid(person);
    },
    isMetricsValid: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return isMetricsValid(person);
    },
    isLabsValid: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return isLabsValid(person);
    },
    isDietValid: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return isDietValid(person);
    },
    isExerciseValid: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return isExerciseValid(person);
    },
    isCardiovascularValid: (state) => (uuid) => {
        const person = find(state.family, { uuid: uuid });
        return isCardiovascularValid(person);
    },
    isFamilyMemberValid: (state) => (uuid) => {
        const familyMember = find(state.family, { uuid: uuid });
        return isValid(familyMember);
    },
    allFamilyMembersValid: (state) => {
        return allFamilyMembersValid(state.family);
    },
    maternalGrandparents: (state, getters) => {
        return filter(getters.maternalFamily, (item) => {
            return /^MGR.TH$/.test(item.relation);
        });
    },
    paternalGrandparents: (state, getters) => {
        return filter(getters.paternalFamily, (item) => {
            return /^PGR.TH$/.test(item.relation);
        });
    },
    selfParents: (state) => {
        return filter(state.family, (item) => {
            return /^N.TH$/.test(item.relation);
        });
    },
    maternalFamily: (state) => {
        return filter(state.family, (item) => {
            return isMaternal(item.relation);
        });
    },
    paternalFamily: (state) => {
        return filter(state.family, (item) => {
            return isPaternal(item.relation);
        });
    },
    selfFamily: (state) => {
        return filter(state.family, (item) => {
            return !isPaternal(item.relation) & !isMaternal(item.relation);
        });
    }
};
const mutations = {
    ...make.mutations(state),
    addFamilyMember(state, person) {
        const familyMember = createFamilyMember(person);

        const mother = person.mother ? find(state.family, { uuid: person.mother }) : null;
        const father = person.father ? find(state.family, { uuid: person.father }) : null;
        const self = state.family[0];

        // eslint-disable-next-line no-unused-vars
        const combine = (prop) => {
            if (mother && father) {
                return union(mother[prop], father[prop]);
            } else if (mother) {
                return mother[prop];
            } else if (father) {
                return father[prop];
            }
            return self[prop];
        };

        // default race/ethnicity for new family members to combination of parents, single parent, or self
        // familyMember.race = combine('race');
        // familyMember.ethnicity = combine('ethnicity');

        const parentUuid = father?.uuid || mother?.uuid;

        if (parentUuid) {
            const index = findIndex(state.family, { uuid: parentUuid });
            if (index !== -1) {
                state.family.splice(index + 1, 0, familyMember);
            }
        } else {
            state.family.push(familyMember);
        }
    },
    removeFamilyMember(state, person) {
        const index = findIndex(state.family, { uuid: person.uuid });
        if (index !== -1) {
            const multiple = state.family[index].multiple;
            if (multiple) {
                // removeTwins
                const uuids = multiple.fraternal.concat(multiple.identical);
                uuids.forEach((uuid) => {
                    const index = findIndex(state.family, { uuid: uuid });
                    if (index !== -1) {
                        state.family[index].multiple = null;
                    }
                });
            }
            state.family.splice(index, 1);
            if (state.selectedUuid === person.uuid) {
                state.selectedUuid = null;
            }
        }
    },
    addFamilyMemberCondition(state, { person, condition, causeOfDeath, property = 'conditions' }) {
        if (person.uuid) {
            const index = findIndex(state.family, { uuid: person.uuid });
            if (index !== -1) {
                if (!state.family[index][property]) {
                    Vue.set(state.family[index], property, []);
                }
                state.family[index][property].push(condition);
                if (causeOfDeath) {
                    state.family[index].causeOfDeath = `condition:${condition.id}`;
                }
            }
        }
    },
    updateFamilyMemberCondition(state, { person, condition, causeOfDeath, property = 'conditions' }) {
        if (person.uuid && condition.uuid) {
            const index = findIndex(state.family, { uuid: person.uuid });
            if (index !== -1) {
                const conditionIndex = findIndex(state.family[index][property], { uuid: condition.uuid });
                if (conditionIndex !== -1) {
                    state.family[index][property].splice(conditionIndex, 1, condition);
                    if (causeOfDeath) {
                        state.family[index].causeOfDeath = `condition:${condition.id}`;
                    } else if (`condition:${condition.id}` === state.family[index].causeOfDeath) {
                        // reset causeOfDeath if it is currently set to this condition and causeOfDeath is no longer checked
                        state.family[index].causeOfDeath = '';
                    }
                }
            }
        }
    },
    removeFamilyMemberCondition(state, { person, condition, property = 'conditions' }) {
        if (person.uuid && condition.uuid) {
            const index = findIndex(state.family, { uuid: person.uuid });
            if (index !== -1) {
                const conditionIndex = findIndex(state.family[index][property], { uuid: condition.uuid });
                if (conditionIndex !== -1) {
                    state.family[index][property].splice(conditionIndex, 1);
                    if (`condition:${condition.id}` === state.family[index].causeOfDeath) {
                        // reset causeOfDeath if condition being removed is the current causeOfDeath
                        state.family[index].causeOfDeath = '';
                    }
                }
            }
        }
    },
    addTwins(state, twins) {
        const fraternal = twins.fraternal.map((person) => person.uuid);
        const identical = twins.identical.map((person) => person.uuid);
        const uuids = fraternal.concat(identical);
        uuids.forEach((uuid) => {
            const index = findIndex(state.family, { uuid: uuid });
            if (index !== -1) {
                state.family[index].multiple = { fraternal, identical };
            }
        });
    },
    removeTwins(state, multiple) {
        const uuids = multiple.fraternal.concat(multiple.identical);
        uuids.forEach((uuid) => {
            const index = findIndex(state.family, { uuid: uuid });
            if (index !== -1) {
                state.family[index].multiple = null;
            }
        });
    },
    updateSelfParents(state, { NMTH, NFTH }) {
        state.family[0].mother = NMTH.uuid;
        state.family[0].father = NFTH.uuid;
    },
    loadPreferences(state, preferences) {
        Object.assign(state.preferences, preferences);
    },
    loadPatientData(state, data) {
        Object.assign(state, data);
    },
    resetData(state) {
        state.__v = null;
        state.family = initFamily();
    },
    resetDefaultState(state) {
        Object.assign(state, defaultState());
    }
};
const actions = {
    ...make.actions(state),
    async createFamily({ commit, getters }, nicknames) {
        commit('addFamilyMember', { relation: 'MGRMTH', gender: 'female', nickname: nicknames.MGRMTH });
        commit('addFamilyMember', { relation: 'MGRFTH', gender: 'male', nickname: nicknames.MGRFTH });
        commit('addFamilyMember', { relation: 'PGRMTH', gender: 'female', nickname: nicknames.PGRMTH });
        commit('addFamilyMember', { relation: 'PGRFTH', gender: 'male', nickname: nicknames.PGRFTH });

        const MGRMTH = getters.relative('MGRMTH');
        const MGRFTH = getters.relative('MGRFTH');
        const PGRMTH = getters.relative('PGRMTH');
        const PGRFTH = getters.relative('PGRFTH');

        commit('addFamilyMember', { relation: 'NMTH', gender: 'female', father: MGRFTH.uuid, mother: MGRMTH.uuid, nickname: nicknames.NMTH });
        commit('addFamilyMember', { relation: 'NFTH', gender: 'male', father: PGRFTH.uuid, mother: PGRMTH.uuid, nickname: nicknames.NFTH });

        const NMTH = getters.relative('NMTH');
        const NFTH = getters.relative('NFTH');

        commit('updateSelfParents', { NMTH, NFTH });

        await flushDebounced();
    },
    async save({ state }) {
        debouncePatientSave(this, state);
        await debouncePatientSave.flush();
    },
    async initPedigree({ commit }) {
        commit('resetDefaultState');
        commit('patient/resetDefaultState');
        commit('provider/resetDefaultState');
        commit('researcher/resetDefaultState');
        commit('busy', true);
        try {
            const { data: user } = await Vue.prototype.$api.get('/login');
            commit('user', user);

            try {
                await wait(1000);

                const { data: patient } = await Vue.prototype.$api.get('/patient');
                commit('patient/patient', patient);
                commit('organization', patient.organization);
                if (patient?.data) {
                    commit('loadPatientData', patient.data);
                    if (patient.data.family[0]?.language) {
                        i18n.locale = patient.data.family[0].language;
                    }
                }
            } catch (error) {
                // TODO: display error message?
            }
        } catch (error) {
            commit('user', null);
        }
        commit('busy', false);
    },
    async init({ commit, dispatch }) {
        commit('resetDefaultState');
        commit('patient/resetDefaultState');
        commit('provider/resetDefaultState');
        commit('researcher/resetDefaultState');
        commit('busy', true);
        try {
            const { data: user } = await Vue.prototype.$api.get('/login');
            commit('user', user);

            if ('patient' === user.type) {
                try {
                    const { data: patient } = await Vue.prototype.$api.get('/patient');
                    commit('patient/patient', patient);
                    commit('organization', patient.organization);
                    if (patient?.data) {
                        commit('loadPatientData', patient.data);
                        if (patient.data.family[0]?.language) {
                            i18n.locale = patient.data.family[0].language;
                        }
                    }
                } catch (error) {
                    // TODO: display error message?
                }
            } else if ('practitioner' === user.type) {
                try {
                    const { data } = await Vue.prototype.$api.get('/practitioner');
                    commit('organization', data.provider.organization);
                    commit('provider/patients', data.provider.patients);
                    if (data.patientId) {
                        // run asynchronously
                        dispatch('provider/selectPatient', { patientId: data.patientId }).then(() => {});
                    }
                } catch (error) {
                    commit('provider/patients', null);
                }
            } else if ('researcher' === user.type) {
                try {
                    const { data: organizations } = await Vue.prototype.$api.get('/researcher');
                    commit('researcher/organizations', organizations);
                } catch (error) {
                    commit('researcher/organizations', null);
                }
            }
        } catch (error) {
            commit('user', null);
        }
        commit('busy', false);
    },
    async exitEditData({ commit, dispatch, getters }, { selectedPatient }) {
        commit('busy', true);
        try {
            await flushDebounced();
            if (getters.error) {
                getters.error.wasSaving = true;
                commit('error', { error: getters.error, wasSaving: true });
                commit('busy', false);
                return;
            }
            if ('practitioner' === getters.userType) {
                const { data } = await Vue.prototype.$api.get('/practitioner');
                commit('provider/patients', data.provider.patients);
                if (data.patientId) {
                    // run asynchronously
                    dispatch('provider/selectPatient', { patientId: data.patientId }).then(() => {});
                } else {
                    commit('provider/selectedPatient', null);
                }
            } else if ('researcher' === getters.userType) {
                const search = getters['researcher/search'];
                const searchType = getters['researcher/searchType'];
                const { data: organizations } = await Vue.prototype.$api.get(`/researcher${search ? `?search=${search}${'startswith' === searchType ? '&fast' : ''}` : ''}`);
                commit('researcher/organizations', organizations);
                commit('researcher/selectedPatient', null);
            }
            await Vue.prototype.$api.post(`/${getters.userType}/log`, { type: 'edit:exit', patientId: selectedPatient.id });
            commit('resetData');
            commit('mode', null);
        } catch (error) {
            commit('error', { error });
        } finally {
            commit('busy', false);
        }
    },
    async refreshPatientData({ commit, dispatch, getters }, { selectedPatient }) {
        commit('busy', true);
        try {
            if ('patient' === getters.userType) {
                const { data: patient } = await Vue.prototype.$api.get('/patient');
                commit('patient/patient', patient);
                commit('organization', patient.organization);
                if (patient?.data) {
                    commit('loadPatientData', patient.data);
                    if (patient.data.family[0]?.language) {
                        i18n.locale = patient.data.family[0].language;
                    }
                }
            } else if ('practitioner' === getters.userType) {
                await dispatch('provider/selectPatient', { patientId: selectedPatient.id, type: 'edit' });
            } else if ('researcher' === getters.userType) {
                await dispatch('researcher/selectPatient', { patientId: selectedPatient.id, type: 'edit' });
            }
        } catch (error) {
            commit('error', { error });
        } finally {
            commit('busy', false);
        }
    },
    async logout({ commit }) {
        commit('busy', true);
        await flushDebounced();
        commit('user', null);
        let redirect = null;
        return Vue.prototype.$api
            .post('/logout')
            .then(({ data }) => (redirect = data.redirect))
            .finally(() => {
                commit('busy', false);
                commit('resetDefaultState');
                commit('patient/resetDefaultState');
                commit('provider/resetDefaultState');
                commit('researcher/resetDefaultState');
                if (redirect) {
                    window.location.replace(redirect);
                }
            });
    }
};

export default new Vuex.Store({
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules,
    plugins: [pathify.plugin, autoSave]
});
