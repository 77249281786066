<template>
    <v-card>
        <v-toolbar dense dark color="primary">
            <v-toolbar-title>{{ $t(`conditions.${conditionId}.name`) }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-body-1">
            <div class="mb-3" v-html="$t('resource.condition', { name: $t(`conditions.${conditionId}.name`) })" v-if="!public"></div>
            <vue-markdown :anchorAttributes="anchorAttributes" v-if="blurb" class="primary--text">{{ blurb }}</vue-markdown>
            <div v-if="resource">
                <div class="mt-3">{{ $t('resource.resource') }}</div>
                <vue-markdown :anchorAttributes="anchorAttributes">{{ resource }}</vue-markdown>
            </div>
            <div v-if="support">
                <div class="mt-3">{{ $t('resource.support') }}</div>
                <vue-markdown :anchorAttributes="anchorAttributes">{{ support }}</vue-markdown>
            </div>
            <div v-if="limited">
                <div class="mt-3">{{ $t('resource.limited') }}</div>
                <vue-markdown :anchorAttributes="anchorAttributes">{{ limited }}</vue-markdown>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import VueMarkdown from '@adapttive/vue-markdown';

    export default {
        components: { VueMarkdown },
        props: {
            conditionId: {
                type: String,
                required: true
            },
            public: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            anchorAttributes: {
                target: '_blank'
            }
        }),
        computed: {
            blurb() {
                return this.$i18n.t(`conditions.${this.conditionId}.md.blurb`);
            },
            resource() {
                return this.$i18n.t(`conditions.${this.conditionId}.md.resource`);
            },
            support() {
                return this.$i18n.t(`conditions.${this.conditionId}.md.support`);
            },
            limited() {
                return this.$i18n.t(`conditions.${this.conditionId}.md.limited`);
            }
        }
    };
</script>
