import { colors } from '@/common';
import '@fortawesome/fontawesome-pro/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa'
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: colors
        }
    }
});
