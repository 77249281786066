import { isPaternal, isMaternal } from '@/common';
import { compact, filter } from 'lodash';
import { get, sync } from 'vuex-pathify';

const FamilyMixin = {
    computed: {
        family: get('family'),
        selectedUuid: sync('selectedUuid'),
        selectedFamilyMember: get('selectedFamilyMember'),
        maternalGrandparents: get('maternalGrandparents'),
        paternalGrandparents: get('paternalGrandparents'),
        selfParents: get('selfParents'),
        maternalFamily: get('maternalFamily'),
        paternalFamily: get('paternalFamily'),
        selfFamily: get('selfFamily')
    },
    methods: {
        getFamilyMember(uuid) {
            return this.$store.get('familyMember', uuid);
        },
        parents(person) {
            return compact([this.$store.get('familyMember', person.mother), this.$store.get('familyMember', person.father)]);
        },
        children(person) {
            if ('female' === person.gender) {
                return filter(this.family, { mother: person.uuid });
            }
            return filter(this.family, { father: person.uuid });
        },
        addTwins() {
            this.$root.$twins
                .add()
                .then((twins) => {
                    this.$store.commit('addTwins', twins);
                })
                .catch(() => {});
        },
        viewTwins(multiple) {
            this.$root.$twins
                .view(multiple)
                .then(() => {})
                .catch(() => {});
        },
        add(person) {
            this.$root.$addFamilyMember
                .show(person)
                .then(() => {
                    this.$store.commit('addFamilyMember', person);
                })
                .catch(() => {});
        },
        addMaternalAunt() {
            this.add({ father: this.$store.get('relative', 'MGRFTH').uuid, mother: this.$store.get('relative', 'MGRMTH').uuid, relation: 'MAUNT', gender: 'female', nickname: '' });
        },
        addMaternalUncle() {
            this.add({ father: this.$store.get('relative', 'MGRFTH').uuid, mother: this.$store.get('relative', 'MGRMTH').uuid, relation: 'MUNCLE', gender: 'male', nickname: '' });
        },
        addPaternalAunt() {
            this.add({ father: this.$store.get('relative', 'PGRFTH').uuid, mother: this.$store.get('relative', 'PGRMTH').uuid, relation: 'PAUNT', gender: 'female', nickname: '' });
        },
        addPaternalUncle() {
            this.add({ father: this.$store.get('relative', 'PGRFTH').uuid, mother: this.$store.get('relative', 'PGRMTH').uuid, relation: 'PUNCLE', gender: 'male', nickname: '' });
        },
        addSister() {
            this.add({ father: this.$store.get('relative', 'NFTH').uuid, mother: this.$store.get('relative', 'NMTH').uuid, relation: 'NSIS', gender: 'female', nickname: '' });
        },
        addBrother() {
            this.add({ father: this.$store.get('relative', 'NFTH').uuid, mother: this.$store.get('relative', 'NMTH').uuid, relation: 'NBRO', gender: 'male', nickname: '' });
        },
        canAddChild(relation) {
            return /^SELF$|^DAU$|^SON$|SIS$|BRO$|AUNT$|UNCLE$/.test(relation);
        },
        addChild(parent, gender) {
            const child = { gender: gender, nickname: '' };

            if ('female' === parent.gender) {
                child.mother = parent.uuid;
            } else if ('male' === parent.gender) {
                child.father = parent.uuid;
            } else {
                // TODO: error
            }

            if (/^SELF$/.test(parent.relation)) {
                if ('female' === gender) {
                    child.relation = 'DAU';
                } else if ('male' === gender) {
                    child.relation = 'SON';
                } else {
                    // TODO: error
                }
            } else if (/^DAU$|^SON$/.test(parent.relation)) {
                if ('female' === gender) {
                    child.relation = 'GRNDDAU';
                } else if ('male' === gender) {
                    child.relation = 'GRNDSON';
                } else {
                    // TODO: error
                }
            } else if (/SIS$|BRO$/.test(parent.relation)) {
                if ('female' === gender) {
                    child.relation = 'NIECE';
                } else if ('male' === gender) {
                    child.relation = 'NEPHEW';
                } else {
                    // TODO: error
                }
            } else if (/AUNT$|UNCLE$/.test(parent.relation)) {
                if (isMaternal(parent.relation)) {
                    child.relation = 'MCOUSN';
                } else if (isPaternal(parent.relation)) {
                    child.relation = 'PCOUSN';
                } else {
                    // TODO: error
                }
            } else {
                // TODO: error
            }

            this.add(child);
        },
        indent(relation) {
            if (/^GRND|COUSN$/.test(relation)) {
                return 2;
            } else if (/^NMTH$|^NFTH$|AUNT$|UNCLE$|^DAU$|^SON$|^NIECE$|^NEPHEW$/.test(relation)) {
                return 1;
            }
            return 0;
        },
        isSibling(relation) {
            return /SIS$|BRO$/.test(relation);
        },
        isValid(person) {
            return this.$store.get('isFamilyMemberValid', person.uuid);
        },
        showChildren(relation) {
            return !/COUSN$|^GRND|^NIECE$|^NEPHEW$/.test(relation);
        },
        showParents(relation) {
            return !/^.GR.TH$/.test(relation);
        },
        canRemove(person) {
            return !/PGRFTH|PGRMTH|MGRFTH|MGRMTH|NFTH|NMTH|SELF/.test(person.relation) && 0 === filter(this.family, { father: person.uuid }).length && 0 === filter(this.family, { mother: person.uuid }).length;
        },
        remove(person) {
            if (this.canRemove(person)) {
                this.$root.$confirmationDialog
                    .show({ title: this.$i18n.t('prompts.removeFamilyMember.title'), message: this.$i18n.t('prompts.removeFamilyMember.message', { nickname: person.nickname || this.$i18n.t('prompts.removeFamilyMember.default') }) })
                    .then(() => {
                        this.$store.commit('removeFamilyMember', person);
                    })
                    .catch(() => {});
            }
        },
        canRemoveParent(person) {
            return /^NSIS$|^NBRO$|AUNT$|UNCLE$/.test(person.relation) && person.mother && person.father;
        },
        removeParent(person, parent) {
            if (parent.uuid === person.mother) {
                person.mother = null;
            } else if (parent.uuid === person.father) {
                person.father = null;
            }
            if (/^NSIS$|^NBRO$/.test(person.relation)) {
                person.relation = 'H' + person.relation.substring(1);
            }
        },
        iconClass(person) {
            if (isPaternal(person.relation)) {
                return 'secondary';
            } else if (isMaternal(person.relation)) {
                return 'accent';
            } else {
                return 'primary white--text';
            }
        },
        iconTextClass(person) {
            if (isPaternal(person.relation)) {
                return 'secondary--text';
            } else if (isMaternal(person.relation)) {
                return 'accent--text';
            } else {
                return 'primary--text';
            }
        }
    }
};

export { FamilyMixin };
