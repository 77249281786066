import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
const locale = navigatorLocale ? navigatorLocale.trim().split(/[-_]/)[0] : 'en';

function loadLocaleMessages() {
    const locales = require.context('./common/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const silentMissingRegex = /^studies._intro|errors\.(.*)|^conditions\.(.*)\.(short|hint|keywords|md\.blurb|md\.resource|md\.support|md\.limited)$/;

export default new VueI18n({
    locale: locale,
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
    silentTranslationWarn: silentMissingRegex,
    // eslint-disable-next-line no-unused-vars
    missing: (locale, key, vm, values) => {
        if (silentMissingRegex.test(key)) {
            return '';
        }
        return null;
    }
});
