<template>
    <v-card>
        <v-toolbar dense dark color="primary">
            <v-toolbar-title>{{ $t(`cdsResources.${topic}.name`) }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-body-1 md-text">
            <vue-markdown :anchorAttributes="anchorAttributes">{{ md }}</vue-markdown>
        </v-card-text>
    </v-card>
</template>

<script>
    import VueMarkdown from '@adapttive/vue-markdown';

    export default {
        components: { VueMarkdown },
        props: {
            topic: {
                type: String,
                required: true
            }
        },
        data: () => ({
            anchorAttributes: {
                target: '_blank'
            }
        }),
        computed: {
            md() {
                return this.$i18n.t(`cdsResources.${this.topic}.md`);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .md-text {
        ::v-deep ul {
            margin-top: -16px;
            margin-bottom: 16px;
        }
        ::v-deep hr {
            margin: 16px 0;
        }
    }
</style>
