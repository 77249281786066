<template>
    <v-card tile flat class="white">
        <v-toolbar dense dark tile flat color="accent" id="tour-report-pedigree-controls" v-if="!plain">
            <v-radio-group row mandatory hide-details class="ma-0" v-model="view">
                <v-radio :label="$t('labels.diagram')" value="diagram"></v-radio>
                <v-radio :label="$t('labels.table')" value="table"></v-radio>
            </v-radio-group>
            <v-spacer></v-spacer>
            <div class="d-flex align-center" v-if="'diagram' === view">
                <v-checkbox v-model="showConditionLabels" :label="$t('labels.showConditionLabels')" class="mx-2 pa-0" dense hide-details></v-checkbox>
                <v-checkbox v-model="showNames" :label="$t('labels.showNames')" class="mx-2 pa-0" dense hide-details></v-checkbox>
                <v-btn small text outlined class="mx-2 btn-outline-solid-white" @click.stop="update()" @click.shift.exact="zoomToSize()" @click.alt.exact="zoomToFit()">
                    <v-icon small left>fas fa-redo</v-icon>
                    {{ $t('common.reset') }}
                </v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn small text outlined class="btn-outline-solid-white" @click.exact="generatePng(false, 2)" @click.shift.exact="generateSvg()" v-if="'diagram' === view">
                <v-icon left small>fas fa-file-image</v-icon>
                {{ $t('labels.download') }}
            </v-btn>
        </v-toolbar>
        <v-card class="white pt-2" flat v-show="'diagram' === view">
            <div id="diagram" class="mx-3"></div>
            <div id="tour-report-pedigree-list" v-if="!plain">
                <v-toolbar dense flat tile dark color="secondary">
                    <v-toolbar-title class="text-subtitle-1">{{ $t('labels.familyMedicalConditions') }}</v-toolbar-title>
                </v-toolbar>
                <div class="text-center black--text my-1">
                    <span class="hint-text">{{ $t('hints.pedigreeConditions') }}</span>
                </div>
                <v-list subheader class="condition-list" v-if="familyConditions && familyConditions.length">
                    <template v-for="(familyCondition, index) in familyConditions">
                        <v-divider :key="index"></v-divider>
                        <v-list-item :key="familyCondition.condition.id" @click="highlight(familyCondition.condition)">
                            <v-list-item-action class="my-0 mr-3">
                                <v-icon :style="{ color: highlightColor(familyCondition.condition) }" v-if="!!highlightColor(familyCondition.condition)">fas fa-square</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="py-0" v-if="familyCondition.condition.group">
                                <v-list-item-title><v-icon small left>fas fa-layer-group</v-icon>{{ familyCondition.condition.groupName }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content class="py-0" v-else>
                                <v-list-item-title>{{ $t(`conditions.${familyCondition.condition.id}.name`) }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action-text>
                                <v-chip label pill small class="ml-2 my-1 pr-0" v-for="familyMember in familyCondition.familyMembers" :key="familyMember.uuid">
                                    {{ familyMember.nickname || relation(familyMember) }}
                                    <v-chip label pill small color="ml-2 primary white--text">{{ familyMember.conditionOnset }}</v-chip>
                                </v-chip>
                            </v-list-item-action-text>
                        </v-list-item>
                    </template>
                </v-list>
            </div>
        </v-card>
        <v-card class="white" v-show="'table' === view">
            <v-simple-table dense class="grid-table">
                <template #default>
                    <thead>
                        <tr>
                            <th colspan="2"></th>
                            <th class="vertical-header pa-3" v-for="(familyCondition, index) in filteredFamilyConditions" :key="index">
                                <div class="vertical-text">{{ conditionName(familyCondition.condition) }}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="person in familyMembersWithConditions" :key="person.uuid">
                            <td class="text-no-wrap">{{ person.nickname || person.firstName }}</td>
                            <td class="text-no-wrap text-caption hint-text">{{ relation(person) }}</td>
                            <td class="text-center pa-0 data-cell" v-for="(familyCondition, index) in filteredFamilyConditions" :key="index">
                                <div class="my-2" v-for="condition in familyMemberConditions(person, familyCondition.condition)" :key="condition.uuid">
                                    <v-chip label pill small class="primary white--text">{{ condition.age }}</v-chip>
                                    <div class="text-caption" v-for="(type, i) in subTypeNames(condition)" :key="i">{{ type }}</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </v-card>
</template>

<script>
    import { GenogramLayout } from '@/components/GenogramLayout';
    import 'gojs';

    import { colors, conditionGroups, relations } from '@/common';
    import { cloneDeep, debounce, each, filter, find, findIndex, keyBy, isNumber, map, sortBy } from 'lodash';
    import { v4 as uuidv4 } from 'uuid';

    const relationMap = keyBy(relations, 'code');

    const $ = go.GraphObject.make;
    if (/duke\.edu/.test(window.location.origin)) {
        go.Diagram.licenseKey = process.env.VUE_APP_GOJS_LICENSE_KEY_DUKE;
    } else {
        go.Diagram.licenseKey = process.env.VUE_APP_GOJS_LICENSE_KEY;
    }

    const slash = go.Geometry.parse('F M38 0 L40 0 40 2 2 40 0 40 0 38z');
    const probandSvgPath = 'M 902.25049,222.98633 H 233.17773 V 364.71875 L 0,182.35938 233.17773,0 v 141.73242 h 669.07276 z';
    const probandGeometry = go.Geometry.parse(probandSvgPath, true);

    export default {
        props: {
            family: {
                type: Array,
                required: true
            },
            diagramConfig: {
                type: Object,
                default: () => ({
                    showConditionLabels: true,
                    showNames: true
                })
            },
            plain: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            diagram: null,
            legend: null,
            view: 'diagram',
            showConditionLabels: true,
            showNames: true,
            highlightColors: ['blue', 'red', 'green', 'magenta'],
            highlightedConditions: []
        }),
        computed: {
            familyMembersWithConditions() {
                return filter(cloneDeep(this.decoratedFamily), (person) => {
                    return person.conditions && person.conditions.length > 0;
                });
            },
            filteredFamilyConditions() {
                return filter(this.familyConditions, (familyCondition) => {
                    return !familyCondition.condition.group && !/^other/.test(familyCondition.condition.id);
                });
            },
            decoratedFamily() {
                const decoratedFamily = cloneDeep(this.family);
                for (const person of decoratedFamily) {
                    if (!person.mother && person.father) {
                        const uuid = uuidv4();
                        const mother = {
                            uuid,
                            _hidden: true,
                            gender: 'female'
                        };
                        person.mother = uuid;
                        const father = this.familyMember(person.father, decoratedFamily);
                        for (const child of this.children(father, decoratedFamily)) {
                            if (!child.mother) {
                                child.mother = uuid;
                            }
                        }
                        decoratedFamily.push(mother);
                    }
                    if (!person.father && person.mother) {
                        const uuid = uuidv4();
                        const father = {
                            uuid,
                            _hidden: true,
                            gender: 'male'
                        };
                        person.father = uuid;
                        const mother = this.familyMember(person.mother, decoratedFamily);
                        for (const child of this.children(mother, decoratedFamily)) {
                            if (!child.father) {
                                child.father = uuid;
                            }
                        }
                        decoratedFamily.push(father);
                    }
                }
                return sortBy(decoratedFamily, (person) => {
                    return person.relation ? relationMap[person.relation]?.sort : relations.length + 1;
                });
            },
            familyConditions() {
                let conditions = {};
                let groups = {};
                each(this.decoratedFamily, (person) => {
                    each(person.conditions, (condition) => {
                        if (!conditions[condition.id]) {
                            condition = Object.assign(cloneDeep(condition), {
                                name: this.$i18n.t(`conditions.${condition.id}.name`),
                                hint: this.$i18n.t(`conditions.${condition.id}.hint`),
                                keywords: this.$i18n.t(`conditions.${condition.id}.keywords`)
                            });
                            conditions[condition.id] = { condition, familyMembers: [] };
                        }
                        if (!find(conditions[condition.id].familyMembers, { uuid: person.uuid })) {
                            conditions[condition.id].familyMembers.push(Object.assign(cloneDeep(person), { conditionOnset: condition.age }));
                        }

                        each(conditionGroups, (conditionGroup) => {
                            if (conditionGroup.rule(condition.id)) {
                                if (!groups[conditionGroup.id]) {
                                    conditionGroup = Object.assign(cloneDeep(conditionGroup), {
                                        group: true,
                                        groupName: this.$i18n.t(`conditionGroups.${conditionGroup.id}`)
                                    });
                                    groups[conditionGroup.id] = { condition: conditionGroup, familyMembers: [] };
                                }
                                if (!find(groups[conditionGroup.id].familyMembers, { uuid: person.uuid })) {
                                    groups[conditionGroup.id].familyMembers.push(Object.assign(cloneDeep(person), { conditionOnset: condition.age }));
                                }
                            }
                        });
                    });
                });
                return map(groups).concat(map(conditions));
            },
            familyStructure() {
                const family = this.decoratedFamily;
                return family.map((person) => {
                    const causeOfDeath = person.causeOfDeath ? `, ${this.causeOfDeathText(person.causeOfDeath)}` : '';
                    const age = 'Deceased' === person.living ? `d. ${isNumber(person.ageAtDeath) ? person.ageAtDeath : '?'}y${causeOfDeath}` : isNumber(person.age) ? `${person.age}y` : '';
                    return {
                        key: this.familyMemberIndex(person.uuid, family),
                        hidden: person._hidden || false,
                        proband: 'SELF' === person.relation ? true : false,
                        conditions: this.showConditionLabels
                            ? map(person.conditions, (condition) => ({ name: this.conditionName(condition, true) + (isNumber(condition.age) ? `, ${condition.age}y` : ''), color: this.conditionColor(condition) }))
                            : [],
                        n: this.showNames || 'SELF' === person.relation ? person.nickname || person.firstName : person.relation ? this.relation(person) : '',
                        age: age,
                        s: person.gender?.substr(0, 1).toUpperCase(),
                        m: this.familyMemberIndex(person.mother, family),
                        f: this.familyMemberIndex(person.father, family),
                        ux: this.familyMemberIndex(this.wife(person, family), family),
                        vir: this.familyMemberIndex(this.husband(person, family), family),
                        a: this.attributes(person)
                    };
                });
            }
        },
        watch: {
            family: {
                handler: debounce(function () {
                    this.draw();
                }, 500),
                deep: true
            },
            highlightedConditions() {
                this.draw();
            },
            showConditionLabels() {
                this.draw();
            },
            showNames() {
                this.draw();
            }
        },
        methods: {
            relation(person) {
                let prefix = '';
                const mother = person.mother ? this.familyMember(person.mother, this.decoratedFamily) : null;
                const father = person.father ? this.familyMember(person.father, this.decoratedFamily) : null;
                if (/AUNT$|UNCLE$/.test(person.relation) && (mother?._hidden || father?._hidden)) {
                    prefix = `${this.$i18n.t('relations.half')} `;
                }
                return `${prefix}${this.$i18n.t(`relations.${person.relation}`)}`;
            },
            familyMemberConditions(person, condition) {
                return filter(person.conditions, { id: condition.id });
            },
            conditionColor(condition) {
                const index = findIndex(this.highlightedConditions, (highlightedCondition) => {
                    if (highlightedCondition.group) {
                        return highlightedCondition.rule(condition.id);
                    }
                    return highlightedCondition.id === condition.id;
                });
                if (index > -1) {
                    return this.highlightColors[index];
                }
                return 'black';
            },
            subTypeNames(condition) {
                const types = [];
                if (condition.meta) {
                    if (condition.meta.cancerTypes) {
                        for (let type of condition.meta.cancerTypes) {
                            if (type.selected) {
                                types.push(this.$i18n.t(`conditions.${condition.id}.${type.id}|short`) || this.$i18n.t(`conditions.${condition.id}.${type.id}`));
                            }
                        }
                    }
                    if (condition.meta.other) {
                        types.push(condition.meta.other);
                    }
                }
                return types;
            },
            conditionName(condition, showSubTypes = false) {
                const types = showSubTypes ? this.subTypeNames(condition) : [];
                const suffix = types.length ? `:${types.join(',')}` : '';
                return (this.$i18n.t(`conditions.${condition.id}.short`) || this.$i18n.t(`conditions.${condition.id}.name`)) + suffix;
            },
            causeOfDeathText(value) {
                if (/^condition:/.test(value)) {
                    const key = value.replace(/^condition:/, '');
                    return this.$i18n.t(`conditions.${key}.name`);
                }
                return this.$i18n.t(`causesOfDeath.${value}`);
            },
            familyMember(uuid, family) {
                return find(family, { uuid: uuid });
            },
            familyMemberIndex(uuid, family) {
                const index = findIndex(family, { uuid: uuid });
                if (index > -1) {
                    return index;
                }
                return undefined;
            },
            parents(person, family) {
                return {
                    mother: this.familyMember(person.mother, family),
                    father: this.familyMember(person.father, family)
                };
            },
            children(person, family) {
                if ('female' === person.gender) {
                    return filter(family, { mother: person.uuid });
                }
                return filter(family, { father: person.uuid });
            },
            wife(person, family) {
                if ('male' === person.gender) {
                    for (const child of this.children(person, family)) {
                        const parents = this.parents(child, family);
                        if (parents.mother) {
                            return parents.mother.uuid;
                        }
                    }
                }
                return undefined;
            },
            husband(person, family) {
                if ('female' === person.gender) {
                    for (const child of this.children(person, family)) {
                        const parents = this.parents(child, family);
                        if (parents.father) {
                            return parents.father.uuid;
                        }
                    }
                }
                return undefined;
            },
            highlightColor(condition) {
                const index = findIndex(this.highlightedConditions, { id: condition.id });
                if (index !== -1) {
                    return this.highlightColors[index];
                }
                return null;
            },
            highlight(condition) {
                const index = findIndex(this.highlightedConditions, { id: condition.id });
                if (index !== -1) {
                    return this.highlightedConditions.splice(index, 1);
                }

                if (this.highlightedConditions.length === this.highlightColors.length) {
                    this.highlightedConditions.shift();
                }
                this.highlightedConditions.push(condition);
            },
            attributes(person) {
                const a = [];
                each(this.highlightedConditions, (condition, index) => {
                    if (condition.group) {
                        if (find(person.conditions, (_condition) => condition.rule(_condition.id))) {
                            a.push(`${index}`);
                        }
                    } else if (find(person.conditions, { id: condition.id })) {
                        a.push(`${index}`);
                    }
                });
                if ('Deceased' === person.living) {
                    a.push('D');
                }
                return a;
            },
            init() {
                const _this = this;

                this.diagram = $(go.Diagram, 'diagram', {
                    initialAutoScale: go.Diagram.Uniform,
                    'undoManager.isEnabled': true,
                    // when a node is selected, draw a big yellow circle behind it
                    nodeSelectionAdornmentTemplate: $(
                        go.Adornment,
                        'Auto',
                        { layerName: 'Grid' }, // the predefined layer that is behind everything else
                        $(go.Shape, 'Circle', { fill: colors.accent, stroke: null }),
                        $(go.Placeholder, { margin: 5 })
                    ),
                    layout: $(GenogramLayout, { direction: 90, layerSpacing: 30, columnSpacing: 10 })
                });

                // determine the color for each attribute shape
                function attrFill(a) {
                    switch (a) {
                        case '0':
                            return _this.highlightColors[0];
                        case '1':
                            return _this.highlightColors[1];
                        case '2':
                            return _this.highlightColors[2];
                        case '3':
                            return _this.highlightColors[3];
                        case 'D':
                            return 'black';
                        default:
                            return 'transparent';
                    }
                }

                // determine the geometry for each attribute shape in a male;
                // except for the slash these are all squares at each of the four corners of the overall square
                var tlsq = go.Geometry.parse('F M1 1 l19 0 0 19 -19 0z');
                var trsq = go.Geometry.parse('F M20 1 l19 0 0 19 -19 0z');
                var brsq = go.Geometry.parse('F M20 20 l19 0 0 19 -19 0z');
                var blsq = go.Geometry.parse('F M1 20 l19 0 0 19 -19 0z');
                function maleGeometry(a) {
                    switch (a) {
                        case '0':
                            return tlsq;
                        case '1':
                            return trsq;
                        case '2':
                            return brsq;
                        case '3':
                            return blsq;
                        case 'D':
                            return slash;
                        default:
                            return tlsq;
                    }
                }

                // determine the geometry for each attribute shape in a female;
                // except for the slash these are all pie shapes at each of the four quadrants of the overall circle
                var tlarc = go.Geometry.parse('F M20 20 B 180 90 20 20 19 19 z');
                var trarc = go.Geometry.parse('F M20 20 B 270 90 20 20 19 19 z');
                var brarc = go.Geometry.parse('F M20 20 B 0 90 20 20 19 19 z');
                var blarc = go.Geometry.parse('F M20 20 B 90 90 20 20 19 19 z');
                function femaleGeometry(a) {
                    switch (a) {
                        case '0':
                            return tlarc;
                        case '1':
                            return trarc;
                        case '2':
                            return brarc;
                        case '3':
                            return blarc;
                        case 'D':
                            return slash;
                        default:
                            return tlarc;
                    }
                }

                // two different node templates, one for each sex,
                // named by the category value in the node data object
                this.diagram.nodeTemplateMap.add(
                    'M', // male
                    $(
                        go.Node,
                        'Spot',
                        { locationSpot: go.Spot.Center, locationObjectName: 'ICON', selectionObjectName: 'ICON' },
                        $(
                            go.Panel,
                            { name: 'ICON' },
                            $(
                                go.Shape,
                                'Square',
                                { width: 40, height: 40, strokeWidth: 2, stroke: 'black', portId: '' },
                                new go.Binding('fill', 'hidden', (val) => {
                                    return val ? 'transparent' : 'white';
                                }),
                                new go.Binding('opacity', 'hidden', (val) => {
                                    return val ? '0.25' : '1';
                                }),
                                new go.Binding('strokeDashArray', 'hidden', (val) => {
                                    return val ? [4, 4] : null;
                                })
                            ),
                            $(
                                go.Panel,
                                {
                                    // for each attribute show a Shape at a particular place in the overall square
                                    itemTemplate: $(go.Panel, $(go.Shape, { stroke: null, strokeWidth: 0 }, new go.Binding('fill', '', attrFill), new go.Binding('geometry', '', maleGeometry))),
                                    margin: 1
                                },
                                new go.Binding('itemArray', 'a')
                            )
                        ),
                        $(
                            go.Panel,
                            'Vertical',
                            { alignment: go.Spot.Bottom, alignmentFocus: go.Spot.Top },
                            $(go.TextBlock, { textAlign: 'center' }, new go.Binding('text', 'n')),
                            $(go.TextBlock, { textAlign: 'center' }, new go.Binding('text', 'age')),
                            $(go.Panel, 'Vertical', new go.Binding('itemArray', 'conditions'), {
                                itemTemplate: $(go.Panel, 'Vertical', $(go.TextBlock, { textAlign: 'center' }, new go.Binding('text', 'name'), new go.Binding('stroke', 'color')))
                            })
                        ),
                        $(go.Shape, { fill: 'black', geometry: probandGeometry, angle: 135, width: 32, height: 16, alignment: new go.Spot(0, 1, -15, 15) }, new go.Binding('visible', 'proband', (val) => val))
                    )
                );

                this.diagram.nodeTemplateMap.add(
                    'F', // female
                    $(
                        go.Node,
                        'Spot',
                        { locationSpot: go.Spot.Center, locationObjectName: 'ICON', selectionObjectName: 'ICON' },
                        $(
                            go.Panel,
                            { name: 'ICON' },
                            $(
                                go.Shape,
                                'Circle',
                                { width: 40, height: 40, strokeWidth: 2, stroke: 'black', portId: '' },
                                new go.Binding('fill', 'hidden', (val) => {
                                    return val ? 'transparent' : 'white';
                                }),
                                new go.Binding('opacity', 'hidden', (val) => {
                                    return val ? '0.25' : '1';
                                }),
                                new go.Binding('strokeDashArray', 'hidden', (val) => {
                                    return val ? [4, 4] : null;
                                })
                            ),
                            $(
                                go.Panel,
                                {
                                    // for each attribute show a Shape at a particular place in the overall circle
                                    itemTemplate: $(go.Panel, $(go.Shape, { stroke: null, strokeWidth: 0 }, new go.Binding('fill', '', attrFill), new go.Binding('geometry', '', femaleGeometry))),
                                    margin: 1
                                },
                                new go.Binding('itemArray', 'a')
                            )
                        ),
                        $(
                            go.Panel,
                            'Vertical',
                            { alignment: go.Spot.Bottom, alignmentFocus: go.Spot.Top },
                            $(go.TextBlock, { textAlign: 'center' }, new go.Binding('text', 'n')),
                            $(go.TextBlock, { textAlign: 'center' }, new go.Binding('text', 'age')),
                            $(go.Panel, 'Vertical', new go.Binding('itemArray', 'conditions'), {
                                itemTemplate: $(go.Panel, 'Vertical', $(go.TextBlock, { textAlign: 'center' }, new go.Binding('text', 'name'), new go.Binding('stroke', 'color')))
                            })
                        ),
                        $(go.Shape, { fill: 'black', geometry: probandGeometry, angle: 135, width: 32, height: 16, alignment: new go.Spot(0, 1, -10, 10) }, new go.Binding('visible', 'proband', (val) => val))
                    )
                );

                // the representation of each label node -- nothing shows on a Marriage Link
                this.diagram.nodeTemplateMap.add('LinkLabel', $(go.Node, { selectable: false, width: 1, height: 1, fromEndSegmentLength: 20 }));

                this.diagram.linkTemplate = $(
                    // for parent-child relationships
                    go.Link,
                    {
                        routing: go.Link.Orthogonal,
                        corner: 5,
                        layerName: 'Background',
                        selectable: false,
                        fromSpot: go.Spot.Bottom,
                        toSpot: go.Spot.Top
                    },
                    $(go.Shape, { stroke: colors.primary, strokeWidth: 2 })
                );

                this.diagram.linkTemplateMap.add(
                    'Marriage', // for marriage relationships
                    $(go.Link, { selectable: false }, $(go.Shape, { strokeWidth: 2.5, stroke: colors.info }))
                );
            },
            draw() {
                // create and initialize the Diagram.model given an array of node data representing people
                function setupDiagram(diagram, array, focusId) {
                    diagram.model = go.GraphObject.make(go.GraphLinksModel, {
                        // declare support for link label nodes
                        linkLabelKeysProperty: 'labelKeys',
                        // this property determines which template is used
                        nodeCategoryProperty: 's',
                        // if a node data object is copied, copy its data.a Array
                        copiesArrays: true,
                        // create all of the nodes for people
                        nodeDataArray: array
                    });
                    setupMarriages(diagram);
                    setupParents(diagram);

                    var node = diagram.findNodeForKey(focusId);
                    if (node !== null) {
                        diagram.select(node);
                    }
                }

                function findMarriage(diagram, a, b) {
                    // A and B are node keys
                    var nodeA = diagram.findNodeForKey(a);
                    var nodeB = diagram.findNodeForKey(b);
                    if (nodeA !== null && nodeB !== null) {
                        var it = nodeA.findLinksBetween(nodeB); // in either direction
                        while (it.next()) {
                            var link = it.value;
                            // Link.data.category === "Marriage" means it's a marriage relationship
                            if (link.data !== null && link.data.category === 'Marriage') return link;
                        }
                    }
                    return null;
                }

                // now process the node data to determine marriages
                function setupMarriages(diagram) {
                    var model = diagram.model;
                    var nodeDataArray = model.nodeDataArray;
                    for (var i = 0; i < nodeDataArray.length; i++) {
                        var data = nodeDataArray[i];
                        var key = data.key;
                        var uxs = data.ux;
                        if (uxs !== undefined) {
                            if (typeof uxs === 'number') uxs = [uxs];
                            for (var j = 0; j < uxs.length; j++) {
                                var wife = uxs[j];
                                if (key === wife) {
                                    // or warn no reflexive marriages
                                    continue;
                                }
                                var link = findMarriage(diagram, key, wife);
                                if (link === null) {
                                    // add a label node for the marriage link
                                    var mlab = { s: 'LinkLabel' };
                                    model.addNodeData(mlab);
                                    // add the marriage link itself, also referring to the label node
                                    var mdata = { from: key, to: wife, labelKeys: [mlab.key], category: 'Marriage' };
                                    model.addLinkData(mdata);
                                }
                            }
                        }
                        var virs = data.vir;
                        if (virs !== undefined) {
                            if (typeof virs === 'number') virs = [virs];
                            for (var j = 0; j < virs.length; j++) {
                                var husband = virs[j];
                                if (key === husband) {
                                    // or warn no reflexive marriages
                                    continue;
                                }
                                var link = findMarriage(diagram, key, husband);
                                if (link === null) {
                                    // add a label node for the marriage link
                                    var mlab = { s: 'LinkLabel' };
                                    model.addNodeData(mlab);
                                    // add the marriage link itself, also referring to the label node
                                    var mdata = { from: key, to: husband, labelKeys: [mlab.key], category: 'Marriage' };
                                    model.addLinkData(mdata);
                                }
                            }
                        }
                    }
                }

                // process parent-child relationships once all marriages are known
                function setupParents(diagram) {
                    var model = diagram.model;
                    var nodeDataArray = model.nodeDataArray;
                    for (var i = 0; i < nodeDataArray.length; i++) {
                        var data = nodeDataArray[i];
                        var key = data.key;
                        var mother = data.m;
                        var father = data.f;
                        if (mother !== undefined && father !== undefined) {
                            var link = findMarriage(diagram, mother, father);
                            if (link === null) {
                                // or warn no known mother or no known father or no known marriage between them
                                if (window.console) window.console.log('unknown marriage: ' + mother + ' & ' + father);
                                continue;
                            }
                            var mdata = link.data;
                            var mlabkey = mdata.labelKeys[0];
                            var cdata = { from: mlabkey, to: key };
                            model.addLinkData(cdata);
                        }
                    }
                }

                setupDiagram(this.diagram, this.familyStructure, 0);

                if (this.legend) {
                    this.diagram.remove(this.legend);
                }

                const itemArray = [
                    { name: this.$i18n.t('genders.male'), color: 'white', figure: 'Square', stroke: 'black', geometry: null },
                    { name: this.$i18n.t('genders.female'), color: 'white', figure: 'Circle', stroke: 'black', geometry: null },
                    { name: this.$i18n.t('livings.Deceased'), color: 'white', figure: null, stroke: 'black', geometry: slash }
                ];
                if (this.highlightedConditions && this.highlightedConditions.length) {
                    each(this.highlightedConditions, (condition, index) => {
                        itemArray.push({ name: condition.groupName || this.conditionName(condition), color: this.highlightColors[index], figure: 'RoundedRectangle', stroke: 'white', geometry: null });
                    });
                }
                this.legend = $(
                    go.Part,
                    { location: new go.Point(0, 0), locationSpot: go.Spot.TopRight },
                    $(go.Panel, 'Vertical', {
                        margin: new go.Margin(0, 10, 0, 0),
                        itemArray,
                        itemTemplate: $(
                            go.Panel,
                            'Horizontal',
                            { alignment: go.Spot.Left },
                            $(go.Shape, { width: 20, height: 20, margin: 4, strokeWidth: 2 }, new go.Binding('fill', 'color'), new go.Binding('figure', 'figure'), new go.Binding('stroke', 'stroke'), new go.Binding('geometry', 'geometry')),
                            $(go.TextBlock, { textAlign: 'left' }, new go.Binding('text', 'name'))
                        )
                    })
                );
                this.diagram.add(this.legend);
            },
            update() {
                const temp = {};
                this.diagram.model.addNodeData(temp);
                this.diagram.model.removeNodeData(temp);
            },
            zoomToFit() {
                this.diagram.commandHandler.zoomToFit();
            },
            zoomToSize() {
                this.diagram.commandHandler.resetZoom();
            },
            download(blob, filename) {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                link.click();
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                }, 100);
            },
            downloadPng(blob) {
                return this.download(blob, `${this.$i18n.t('labels.pedigree')}.png`);
            },
            downloadSvg(blob) {
                return this.download(blob, `${this.$i18n.t('labels.pedigree')}.svg`);
            },
            generatePng(draw, scale = 1) {
                if (draw) {
                    this.draw();
                }
                this.diagram.makeImageData({ scale, maxSize: new go.Size(Infinity, Infinity), background: 'white', returnType: 'blob', callback: this.downloadPng });
            },
            generateSvg(draw) {
                if (draw) {
                    this.draw();
                }
                this.downloadSvg(new Blob([new XMLSerializer().serializeToString(this.diagram.makeSvg({ scale: 1, background: 'white' }))], { type: 'image/svg+xml' }));
            }
        },
        mounted() {
            this.init();
            this.draw();

            this.showConditionLabels = this.diagramConfig.showConditionLabels;
            this.showNames = this.diagramConfig.showNames;

            const params = new URL(document.location).searchParams;
            let scale = null;
            const paramScale = Number.parseInt(params.get('scale'));
            if (Number.isInteger(paramScale)) {
                scale = paramScale;
            }
            const download = params.get('download');
            switch (download) {
                case 'png':
                    this.generatePng(true, scale);
                    break;
                case 'svg':
                    this.generateSvg(true);
                    break;
            }
        }
    };
</script>

<style lang="scss" scoped>
    #diagram {
        ::v-deep canvas {
            outline: none;
        }
        height: 600px;
    }
    .grid-table {
        ::v-deep table {
            td:not(:last-child),
            th:not(:last-child) {
                border-right: thin solid rgba(0, 0, 0, 0.12);
            }
            td.data-cell {
                vertical-align: top;
            }
        }
    }
    .vertical-header {
        min-height: 200px;
        vertical-align: bottom;
    }
    .vertical-text {
        margin: 0 auto;
        text-orientation: mixed;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }
</style>
